import React from 'react';
import { Input as RSInput } from 'reactstrap';

const Input = (props) => {
    return (
        <div>
            <RSInput {...props} />
        </div>
    );
}

export default Input;