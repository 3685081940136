import React, { Component } from 'react';
import TimeKeeper from 'react-timekeeper';
import Input from '../Input';

import './time-picker.css';

export default class TimePicker extends Component {
    constructor(props) {
        super(props);
        this.handleToggleTmk = this.handleToggleTmk.bind(this);
        this.handleChangeTmk = this.handleChangeTmk.bind(this);
        this.state = {
            value: this.props.defaultValue,
            toggleTmk: false,
        }
    }
    handleToggleTmk() {
        this.setState({ toggleTmk: !this.state.toggleTmk });
    }
    handleChangeTmk(data) {
        this.setState({ value: data.formatted24 });
    }
    render() {
        const { name, id } = this.props;
        return (
            <div className='time-picker'>
                <Input type='text' name={name} id={id} value={this.state.value} onClick={this.handleToggleTmk} />
                <div className='tmk-wrapper'>
                {this.state.toggleTmk && <TimeKeeper
                        open={this.state.openPicker}
                        switchToMinuteOnHourSelect={true}
                        closeOnMinuteSelect={true}
                        onChange={this.handleChangeTmk}
                        onDoneClick={this.handleToggleTmk} />}
                </div>
            </div>
        );
    }
}