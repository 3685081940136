export const METHOD_GET = 'get';
export const METHOD_POST = 'post';
export const METHOD_DELETE = 'delete';

export const endpoints = {
    schedule: {
        monthly: { method: METHOD_GET, path: 'schedule' },
        detail: { method: METHOD_GET, path: 'schedule' },
        save: { method: METHOD_POST, path: 'schedule' },
        delete: { method: METHOD_DELETE, path: 'schedule' },
    },
}