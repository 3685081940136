import axios from 'axios';
import { METHOD_GET, METHOD_POST, METHOD_DELETE } from './endpoints';

export class ApiClient {
    constructor() {
        this.api = axios.create({ withCredentials: false });
        this.request = this.request.bind(this);
        this.processResponse = this.processResponse.bind(this);
        this.processError = this.processError.bind(this);
        this.handleGet = this.handleGet.bind(this);
        this.handlePost = this.handlePost.bind(this);
    }

    request(endpoint, params) {
        switch(endpoint.method) {
            case METHOD_GET:
                return this.handleGet(process.env.REACT_APP_SERVER_URL + endpoint.path, params);
            case METHOD_POST:
                return this.handlePost(process.env.REACT_APP_SERVER_URL + endpoint.path, params);
            case METHOD_DELETE:
                return this.handleDelete(process.env.REACT_APP_SERVER_URL + endpoint.path, params);
            default:
                return {}
        }
    }

    processResponse(payload) {
        return { payload: payload.data };
    }
    processError(error) {
        return { error: error };
    }
    handleGet(url, params) {
        return this.api.get(url, { params: params })
        .then(payload => {
            return this.processResponse(payload);
        })
        .catch(error => {
            return this.processError(error);
        });
    }
    handlePost(url, serializedData) {
        return this.api.post(url, serializedData)
        .then(payload => {
            return this.processResponse(payload);
        })
        .catch(error => {
            return this.processError(error);
        });
    }
    handleDelete(url, params) {
        return this.api.delete(url, { params: params })
        .then(payload => {
            return this.processResponse(payload);
        })
        .catch(error => {
            return this.processError(error);
        });
    }
}
export const apiClient = new ApiClient();