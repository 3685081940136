import actions from '../actions';
import { handleActions } from 'redux-actions';
import moment from 'moment';

const initialState = {
    monthlyEvents: {},
    schedule: {},
    loading: false,
}

const refreshEvent = (state, event) => {
    const month = moment(event.start).format('YYYYMM');
    if(!state.monthlyEvents[month]) {
        state.monthlyEvents[month] = { [month]: [] };
    }
    const targetMonth = state.monthlyEvents[month];
    for(var i = 0; i < targetMonth.length ;i++) {
        if(targetMonth[i].eventId === event.eventId) {
            targetMonth[i] = event;
            return state.monthlyEvents;
        }
    }
    targetMonth.push(event);
    return state.monthlyEvents;
}

const reducer = handleActions(
    {
        [actions.monthlySchedule]: (state, action) => ({ ...state,
            loading: true,
        }),
        [actions.monthlyScheduleDone]: (state, action) => ({ ...state,
            monthlyEvents: {...state.monthlyEvents, [action.payload.month]: action.payload.events },
            loading: false,
        }),
        [actions.monthlyScheduleFail]: (state, action) => ({ ...state,
            loading: false,
        }),


        [actions.scheduleDetail]: (state, action) => ({ ...state,
            loading: true,
        }),
        [actions.scheduleDetailDone]: (state, action) => ({ ...state,
            schedule: action.payload,
            loading: false,
        }),
        [actions.scheduleDetailFail]: (state, action) => ({ ...state,
            loading: false,
        }),


        [actions.saveSchedule]: (state, action) => ({ ...state,
            loading: true,
        }),
        [actions.saveScheduleDone]: (state, action) => ({ ...state,
            monthlyEvents: refreshEvent(state, action.payload),
            loading: false,
        }),
        [actions.saveScheduleFail]: (state, action) => ({ ...state,
            loading: false,
        }),


        [actions.deleteSchedule]: (state, action) => ({ ...state,
            loading: true,
        }),
        [actions.deleteScheduleDone]: (state, action) => ({ ...state,
            loading: false,
        }),
        [actions.deleteScheduleFail]: (state, action) => ({ ...state,
            loading: false,
        }),
    },
    initialState,
);

export default reducer;