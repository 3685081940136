import React, { Component } from 'react';
import BigCalendar from 'react-big-calendar';
import moment from 'moment';
import ScheduleModal from '../ScheduleModal';
import { connect } from 'react-redux';
import actions from '../../../actions';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import './calendar.css';

const localizer = BigCalendar.momentLocalizer(moment);

const initialState = {
    selectedDate: moment(),
    selectedEvent: null,
    events: [{}],
    inputModal: false,
}

class Calendar extends Component {

    constructor(props) {
        super(props);
        this.handleSelectSlot = this.handleSelectSlot.bind(this);
        this.handleSelectEvent = this.handleSelectEvent.bind(this);
        this.handleToggleInputModal = this.handleToggleInputModal.bind(this);
        this.handleNavigate = this.handleNavigate.bind(this);
        this.state = initialState;

        const thisMonthEvents = this.props.state.monthlyEvents[this.state.selectedDate.format('YYYYMM')];
        if(thisMonthEvents) {
            this.state.events = thisMonthEvents;
        }
    }

    componentWillMount() {
        this.props.handleMonthly(this.state.selectedDate.format('YYYYMM'));
    }

    componentWillReceiveProps(newProps) {
        const thisMonthEvents = newProps.state.monthlyEvents[this.state.selectedDate.format('YYYYMM')];
        if(thisMonthEvents) {
            this.setState({ events: thisMonthEvents });
        }
    }

    handleSelectSlot(info) {
        this.setState({ selectedDate: moment(info.start) });
        this.handleToggleInputModal();
    }

    handleSelectEvent(event) {
        this.setState({ selectedEvent: event });
        this.handleToggleInputModal();
    }

    handleToggleInputModal() {
        this.setState({ inputModal: !this.state.inputModal });
    }

    handleNavigate(date) {
        this.setState({ selectedDate: moment(date) });
    }

    render() {
        return (
            <div className='cal-wrapper'>
                <BigCalendar
                    localizer={localizer}
                    events={this.state.events}
                    views={['month']}
                    defaultView={BigCalendar.Views.MONTH}
                    selectable={true}
                    onSelectSlot={this.handleSelectSlot}
                    onSelectEvent={this.handleSelectEvent}
                    onNavigate={this.handleNavigate}
                />
                <ScheduleModal
                    isOpen={this.state.inputModal}
                    toggle={this.handleToggleInputModal}
                    date={this.state.selectedDate.toDate()}
                    event={this.state.selectedEvent} />
            </div>
        );
    }
}
const mapStateToProps = state => {
    return { state };
}
const mapDispatchToProps = dispatch => {
    return {
        handleMonthly: (month) => {
            dispatch(actions.monthlySchedule({ month: month }));
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Calendar);