import React, { Component } from 'react';
import { Button, Form, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import DatePicker from 'react-datepicker';
import TimePicker from './TimePicker';
import Input from './Input';

import { connect } from 'react-redux';
import actions from '../../actions';
import { serializeForm } from '../../utils';

import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

const initialState = {
    eventId: null,
    eventType: '01',
    schStartDate: new Date(),
    schEndDate: new Date(),
    schStartTm: '',
    schEndTm: '',
    subject: '',
    place: '',
    detail: '',
}

class ScheduleModal extends Component {
    constructor(props) {
        super(props);
        this.handleChangeStartDate = this.handleChangeStartDate.bind(this);
        this.handleChangeEndDate = this.handleChangeEndDate.bind(this);
        this.handleChangeSubject = this.handleChangeSubject.bind(this);
        this.handleChangePlace = this.handleChangePlace.bind(this);
        this.handleChangeDetail = this.handleChangeDetail.bind(this);
        this.state = initialState;
    }
    componentWillReceiveProps(newProps) {
/*
        this.setState({
            eventId: eventId,
            eventType: eventType,
            schStartDate: schStartDate,
            schEndDate: schEndDate,
            schStartTm: schStartTm,
            schEndTm: schEndTm,
            subject: subject,
            place: place,
            detail: detail,
        });
        */
    }
    handleChangeStartDate(date) {
        this.setState({ schStartDate: date });
    }
    handleChangeEndDate(date) {
        this.setState({ schEndDate: date });
    }
    handleChangeSubject(subject) {
        this.setState({ subject: subject });
    }
    handleChangePlace(place) {
        this.setState({ place: place });
    }
    handleChangeDetail(detail) {
        this.setState({ detail: detail });
    }
    render() {
        const { isOpen, toggle } = this.props;
        return (
            <Modal isOpen={isOpen} toggle={toggle}>
                <ModalHeader>Input Your New Schedule</ModalHeader>
                <ModalBody>
                    <Form ref={r => this.form = r}>
                        <Input type='hidden' name='eventId' id='eventId' value={this.state.eventId} />
                        <Input type='hidden' name='eventType' id='eventType' value={this.state.eventType} />
                        <FormGroup>
                            <DatePicker
                                selected={this.state.schStartDate}
                                name='schStartDate'
                                id='schStartDate'
                                dateFormat="yyyy/MM/dd"
                                className='form-control'
                                onChange={this.handleChangeStartDate} />
                            <TimePicker name='schStartTm' id='sch-start-tm' defaultValue={this.state.schStartTm} />
                            <span> ～ </span>
                            <DatePicker
                                selected={this.state.schEndDate}
                                name='schEndDate'
                                id='schEndDate'
                                dateFormat="yyyy/MM/dd"
                                className='form-control'
                                onChange={this.handleChangeEndDate} />
                            <TimePicker name='schEndTm' id='sch-end-tm' defaultValue={this.state.schEndTm} />
                        </FormGroup>
                        <FormGroup>
                            <Input type='text' name='subject' id='subject' placeholder='subject' value={this.state.subject} onClick={this.handleChangeSubject} />
                        </FormGroup>
                        <FormGroup>
                            <Input type='text' name='place' id='place' placeholder='place' value={this.state.place} onClick={this.handleChangePlace} />
                        </FormGroup>
                        <FormGroup>
                            <Input type='textarea' name='detail' id='detail' placeholder='detail' value={this.state.detail} onClick={this.handleChangeDetail} />
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color='primary' onClick={() => {this.props.handleSave(this.form); toggle();} }>OK</Button>
                    <Button color='danger' onClick={() => {this.props.handleDelete(this.form); toggle();} }>DELETE</Button>
                    <Button color='secondary' onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }
}
const mapStateToProps = state => {
    return { state };
}
const mapDispatchToProps = dispatch => {
    return {
        handleDetail: (eventId) => {
            dispatch(actions.scheduleDetail({ eventId: eventId }));
        },
        handleSave: (data) => {
            dispatch(actions.saveSchedule(serializeForm(data)));
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ScheduleModal);