import createActions from 'redux-actions/lib/createActions';

export const MONTHLY_SCHEDULE = "MONTHLY_SCHEDULE";
export const MONTHLY_SCHEDULE_DONE = "MONTHLY_SCHEDULE_DONE";
export const MONTHLY_SCHEDULE_FAIL = "MONTHLY_SCHEDULE_FAIL";

export const SCHEDULE_DETAIL = "SCHEDULE_DETAIL";
export const SCHEDULE_DETAIL_DONE = "SCHEDULE_DETAIL_DONE";
export const SCHEDULE_DETAIL_FAIL = "SCHEDULE_DETAIL_FAIL";

export const SAVE_SCHEDULE = "SAVE_SCHEDULE";
export const SAVE_SCHEDULE_DONE = "SAVE_SCHEDULE_DONE";
export const SAVE_SCHEDULE_FAIL = "SAVE_SCHEDULE_FAIL";

export const DELETE_SCHEDULE = "DELETE_SCHEDULE";
export const DELETE_SCHEDULE_DONE = "DELETE_SCHEDULE_DONE";
export const DELETE_SCHEDULE_FAIL = "DELETE_SCHEDULE_FAIL";


export default createActions(
    MONTHLY_SCHEDULE,
    MONTHLY_SCHEDULE_DONE,
    MONTHLY_SCHEDULE_FAIL,

    SCHEDULE_DETAIL,
    SCHEDULE_DETAIL_DONE,
    SCHEDULE_DETAIL_FAIL,

    SAVE_SCHEDULE,
    SAVE_SCHEDULE_DONE,
    SAVE_SCHEDULE_FAIL,

    DELETE_SCHEDULE,
    DELETE_SCHEDULE_DONE,
    DELETE_SCHEDULE_FAIL,
);
