import { call, takeEvery, put } from 'redux-saga/effects'
import actions, {
    MONTHLY_SCHEDULE,
    SCHEDULE_DETAIL,
    SAVE_SCHEDULE,
    DELETE_SCHEDULE,
} from '../actions';
import { endpoints } from '../api/endpoints';
import { apiClient } from '../api';

function* handleMonthlySchedule(action) {
    let endpoint = Object.assign({}, endpoints.schedule.monthly);
    endpoint.path += '/' + action.payload.month;
    const { payload, error } = yield call(apiClient.request, endpoint, null);
    if(payload) {
        yield put(actions.monthlyScheduleDone(payload));
    } else {
        yield put(actions.monthlyScheduleFail(error));
    }
}

function* handleScheduleDetail(action) {
    let endpoint = Object.assign({}, endpoints.schedule.detail);
    endpoint.path += '/' + action.payload.eventId + '/detail';
    const { payload, error } = yield call(apiClient.request, endpoint, null);
    if(payload) {
        yield put(actions.scheduleDetailDone(payload));
    } else {
        yield put(actions.scheduleDetailFail(error));
    }
}
function* handleSaveSchedule(action) {
    const { payload, error } = yield call(apiClient.request, endpoints.schedule.save, action.payload);
    if(payload) {
        yield put(actions.saveScheduleDone(payload));
    } else {
        yield put(actions.saveScheduleFail(error));
    }
}

function* handleDeleteSchedule(action) {
    const { payload, error } = yield call(apiClient.request, endpoints.schedule.delete, action.payload);
    if(payload) {
        yield put(actions.deleteScheduleDone(payload));
    } else {
        yield put(actions.deleteScheduleFail(error));
    }
}

export default function* rootSaga() {
    yield takeEvery(MONTHLY_SCHEDULE, handleMonthlySchedule);
    yield takeEvery(SCHEDULE_DETAIL, handleScheduleDetail);
    yield takeEvery(SAVE_SCHEDULE, handleSaveSchedule);
    yield takeEvery(DELETE_SCHEDULE, handleDeleteSchedule);
}